<template>
    <transition name="fade">
        <div
            v-show="showToast"
            class="toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
        >
            <div class="toast-header" :class="toastTitle.toLowerCase()">
                <font-awesome-icon
                    icon="exclamation-circle"
                    class="list-icon mr-2"
                />
                <strong class="mr-auto">{{ toastTitle }}</strong>
                <button
                    type="button"
                    class="ml-2 mb-1 close"
                    data-dismiss="toast"
                    aria-label="Close"
                    @click="hideToast"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="toast-body">
                {{ toastMessage }}
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Toast",
    props: {
        isAuth: Boolean,
        toastTitle: String,
        toastMessage: String,
        showToast: Boolean
    },

    methods: {
        hideToast($event) {
            const toast = {
                status: false,
                title: this.toastTitle,
                message: this.toastMessage
            };
            this.$store.commit("home/SET_TOAST", toast);
        }
    }
};
</script>

<style scoped lang="scss">
.toast {
    opacity: 1;
    position: absolute;
    top: 10%;
    right: 5%;
    z-index: 10;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.info {
    color: rgb(70, 153, 185);
}
.success,
.succes {
    color: rgb(41, 136, 41);
}
.error,
.eroare {
    color: rgb(187, 14, 14);
}
</style>

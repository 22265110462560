import router from "../../router.js";
import { setToastContent /* firebaseUrl */ } from "./../../util";
export default {
    state: {
        user: {
            favorites: [],
            customClauses: [],
        },
        isAuth: false,
    },

    getters: {
        isAuth(state) {
            return state.isAuth;
        },
        getUser(state) {
            return state.user;
        },
        isAdmin(state) {
            return state.user.isAdmin;
        },
        isActive(state) {
            return state.user.active;
        },
    },

    mutations: {
        USER_IS_AUTH(state, payload) {
            state.isAuth = !state.isAuth;
            state.user = Object.assign(state.user, payload);
        },

        USER_IS_LOGOUT(state) {
            state.isAuth = false;
            state.user = {
                favorites: [],
                customClauses: [],
            };
        },

        // ACTIVATE_ACCOUNT(state) {
        //     state.user.active = true;
        // },

        // CHANGE_USER(state, payload) {
        //     state.user = Object.assign(state.user, payload);
        // },

        SET_FAVORITES(state, payload) {
            if ("favorites" in state.user) {
                state.user.favorites = payload;
            }
        },

        SET_CUSTOM_CLAUSES(state, payload) {
            if ("customClauses" in state.user) {
                state.user.customClauses = payload;
            }
        },
    },

    actions: {
        async login({ commit, rootState }, userData) {
            try {
                // const authUrl = firebaseUrl(
                //     "accounts:signInWithPassword?key=AIzaSyAka-1fptQxuOPxwtTvWS7Qow4WpJ0ec7o",
                //     true
                // );
                const apiAuthUrl = "https://easylegal.ro/api/auth";
                const authResponse = await fetch(apiAuthUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: userData.email,
                        password: userData.password,
                        //returnSecureToken: true
                    }),
                });
                const authData = await authResponse.json();
                //console.log(authData);
                if (authData.error) {
                    throw new Error(authData.error.message);
                }

                // const user = {
                //     userId: authData.localId,
                //     email: authData.email,
                //     token: authData.idToken,
                //     refreshToken: authData.refreshToken,
                //     expiresOn: new Date(
                //         new Date().getTime() + authData.expiresIn * 1000
                //     )
                // };
                // const url = firebaseUrl(
                //     `users/${user.userId}.json?auth=${user.token}`
                // );
                const response = await fetch("https://easylegal.ro/api/user", {
                    headers: {
                        Authorization: `Bearer ${authData.access_token}`,
                    },
                });
                const data = await response.json();
                if (data.error) {
                    throw new Error(data.error.message);
                }
                const user = {
                    userId: data.id,
                    name: data.name,
                    email: data.email,
                    token: authData.access_token,
                    isAdmin: data.level == "admin" ? true : false,
                    active: data.active == null ? false : true,
                    favorites: data.favorites || [],
                    customClauses: data.customs || [],
                };
                // user.name = data.name
                // user.email = data.email;
                // user.isAdmin = data.isAdmin;
                // user.level = data.level;
                // user.favorites =
                // user.customClauses =
                // user.active = data.active;
                const toast = setToastContent("success", "login");
                commit("home/SET_TOAST", toast, { root: true });
                commit("USER_IS_AUTH", user);
                localStorage.setItem("user", JSON.stringify(user));
                router.push({ path: "account" });
            } catch (err) {
                const toast = setToastContent("error", "loginFail", err);
                return commit("home/SET_TOAST", toast, { root: true });
            }
        },

        logout({ commit, rootState }) {
            commit("USER_IS_LOGOUT");
            localStorage.removeItem("user");
            const toast = setToastContent("info", "logout");
            commit("home/SET_TOAST", toast, { root: true });
            router.push({ name: "home" });
        },
        // async changeCredentials({ commit, rootState, state }, credentials) {
        //     if (
        //         credentials.newName &&
        //         credentials.newName !== state.user.name
        //     ) {
        //         try {
        //             const url = firebaseUrl(
        //                 `users/${state.user.userId}.json?auth=${state.user.token}`
        //             );
        //             const dbResponse = await fetch(url, {
        //                 method: "PATCH",
        //                 headers: {
        //                     "Content-Type": "application/json",
        //                 },
        //                 body: JSON.stringify({
        //                     name: credentials.newName,
        //                 }),
        //             });
        //             const result = dbResponse.json();
        //             if (result.error) {
        //                 throw new Error(result.error);
        //             }
        //             state.user.name = credentials.newName;
        //             localStorage.setItem("user", JSON.stringify(state.user));
        //             const toast = setToastContent("success", "changeName");
        //             commit("CHANGE_USER", { name: credentials.newName });
        //             commit("home/SET_TOAST", toast, { root: true });
        //         } catch (err) {
        //             //console.log(err);
        //             const toast = setToastContent(
        //                 "error",
        //                 "changeCredentialsFail",
        //                 err
        //             );
        //             commit("home/SET_TOAST", toast, { root: true });
        //         }
        //     }
        //     if (credentials.newPassword) {
        //         try {
        //             const authURl = firebaseUrl(
        //                 "accounts:update?key=AIzaSyAka-1fptQxuOPxwtTvWS7Qow4WpJ0ec7o",
        //                 true
        //             );
        //             const authResponse = await fetch(authURl, {
        //                 method: "POST",
        //                 headers: {
        //                     "Content-Type": "application/json",
        //                 },
        //                 body: JSON.stringify({
        //                     idToken: state.user.token,
        //                     password: credentials.newPassword,
        //                     returnSecureToken: true,
        //                 }),
        //             });
        //             const authData = await authResponse.json();
        //             if (authData.error) {
        //                 throw new Error(authData.error.message);
        //             }
        //             state.user.token = authData.idToken;
        //             state.user.refreshToken = authData.refreshToken;
        //             state.user.expiresOn = new Date(
        //                 new Date().getTime() + authData.expiresIn * 1000
        //             );
        //             const url = firebaseUrl(
        //                 `users/${authData.localId}.json?auth=${authData.idToken}`
        //             );
        //             const dbResponse = await fetch(url, {
        //                 method: "PATCH",
        //                 headers: {
        //                     "Content-Type": "application/json",
        //                 },
        //                 body: JSON.stringify({
        //                     password: credentials.newPassword,
        //                 }),
        //             });
        //             const result = dbResponse.json();
        //             if (result.error) {
        //                 throw new Error(result.error.message);
        //             }
        //             localStorage.setItem("user", JSON.stringify(state.user));
        //             const toast = setToastContent("info", "changePass");
        //             commit("CHANGE_USER", {
        //                 password: credentials.newPassword,
        //             });
        //             commit("home/SET_TOAST", toast, { root: true });
        //         } catch (err) {
        //             //console.log(err);
        //             const toast = setToastContent(
        //                 "error",
        //                 "changeCredentialsFail",
        //                 err
        //             );
        //             commit("home/SET_TOAST", toast, { root: true });
        //         }
        //     }
        // },
        // async activateAccount({ commit, rootState, state }) {
        //     const verifyUrl = firebaseUrl(
        //         "accounts:sendOobCode?key=AIzaSyAka-1fptQxuOPxwtTvWS7Qow4WpJ0ec7o",
        //         true
        //     );
        //     try {
        //         const request = await fetch(verifyUrl, {
        //             method: "POST",
        //             headers: {
        //                 "Content-Type": "application/json",
        //             },
        //             body: JSON.stringify({
        //                 requestType: "VERIFY_EMAIL",
        //                 idToken: state.user.token,
        //             }),
        //         });
        //         const response = await request.json();
        //         console.log(response);
        //         if (response.error) {
        //             throw new Error(response.error.message);
        //         }
        //         if (response.email !== state.user.email) {
        //             const toast = setToastContent("error", "emailMismatch");
        //             return commit("home/SET_TOAST", toast, {
        //                 root: true,
        //             });
        //         }
        //         const toast = setToastContent("info", "activateAccount");
        //         return commit("home/SET_TOAST", toast, { root: true });
        //     } catch (err) {
        //         const toast = setToastContent(
        //             "error",
        //             "activateAccountFail",
        //             err
        //         );
        //         return commit("home/SET_TOAST", toast, {
        //             root: true,
        //         });
        //     }
        // },

        // async confirmActivation({ commit, rootState, state }, payload) {
        //     const url = firebaseUrl(
        //         `users/${payload}.json?auth=${state.user.token}`
        //     );
        //     try {
        //         const response = await fetch(url, {
        //             method: "PATCH",
        //             headers: {
        //                 "Content-Type": "application/json",
        //             },
        //             body: JSON.stringify({ active: true }),
        //         });
        //         const data = await response.json();
        //         console.log(data);
        //         if (data.error) {
        //             throw new Error(data.error.message);
        //         }
        //         const toast = setToastContent("success", "confirmAccount");
        //         commit("ACTIVATE_ACCOUNT");
        //         router.push({ path: "/account" });
        //         return commit("home/SET_TOAST", toast, {
        //             root: true,
        //         });
        //     } catch (err) {
        //         const toast = setToastContent(
        //             "error",
        //             "confirmAccountFail",
        //             err
        //         );
        //         return commit("home/SET_TOAST", toast, {
        //             root: true,
        //         });
        //     }
        // },

        //     async signup({ commit, rootState }, userData) {
        //         const authUrl = firebaseUrl(
        //             "accounts:signUp?key=AIzaSyAka-1fptQxuOPxwtTvWS7Qow4WpJ0ec7o",
        //             true
        //         );
        //         try {
        //             const authResponse = await fetch(authUrl, {
        //                 method: "POST",
        //                 headers: {
        //                     "Content-Type": "application/json",
        //                 },
        //                 body: JSON.stringify({
        //                     email: userData.email,
        //                     password: userData.password,
        //                     returnSecureToken: true,
        //                 }),
        //             });
        //             const authData = await authResponse.json();
        //             console.log(authData);
        //             if (authData.error) {
        //                 throw new Error(authData.error.message);
        //             }
        //             const user = {
        //                 userId: authData.localId,
        //                 email: authData.email,
        //                 name: userData.name,
        //                 isAdmin: false,
        //                 active: false,
        //                 /* token: authData.idToken,
        //                 refreshToken: authData.refreshToken,
        //                 expiresOn: new Date(
        //                     new Date().getTime() + authData.expiresIn * 1000
        //                 ) */
        //             };
        //             const url = firebaseUrl(
        //                 `users/${authData.localId}.json?auth=${authData.idToken}`
        //             );
        //             const response = await fetch(url, {
        //                 method: "PUT",
        //                 headers: {
        //                     "Content-Type": "application/json",
        //                 },
        //                 body: JSON.stringify(user),
        //             });
        //             const data = await response.json();
        //             console.log(data);
        //             if (data.error) {
        //                 throw new Error(data.error.message);
        //             }
        //             const toast = setToastContent("info", "signup");
        //             user.token = authData.idToken;
        //             user.refreshToken = authData.refreshToken;
        //             user.expiresOn = new Date(
        //                 new Date().getTime() + authData.expiresIn * 1000
        //             );
        //             user.favorites = [];
        //             user.customClauses = [];
        //             commit("home/SET_TOAST", toast, { root: true });
        //             commit("USER_IS_AUTH", user);
        //             localStorage.setItem("user", JSON.stringify(user));
        //             router.push({ name: "account" });
        //         } catch (err) {
        //             const toast = setToastContent("error", "signupFail", err);
        //             return commit("home/SET_TOAST", toast, { root: true });
        //         }
        //     },
    },
};

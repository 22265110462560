<template>
    <div class="footer">
        <ul class="footer-list">
            <li class="footer-list-item">
                <font-awesome-icon icon="copyright" class="list-icon" />
                <a href="https://easylegal.ro" target="_blank">Easy Legal</a>
            </li>
            <li class="footer-list-item">
                <font-awesome-icon icon="laptop-code" class="list-icon" />
                Lucian Tanase
            </li>
            <li class="footer-list-item">
                <font-awesome-icon icon="file-contract" class="list-icon" />
                <a @click="displayTerms">{{ $t("footer.terms") }}</a>
            </li>
            <router-link
                class="footer-list-item"
                tag="li"
                to="/privacy"
                active-class="active"
            >
                <a
                    ><font-awesome-icon
                        icon="user-shield"
                        class="list-icon"
                    />{{ $t("footer.privacy") }}</a
                >
            </router-link>
        </ul>

        <div id="overlay-terms">
            <div id="terms">
                <div class="terms-header">
                    <h3>{{ $t("footer.title") }}</h3>
                    <a @click="hideTerms" class="btn-close">&times;</a>
                </div>
                <p v-show="showEN">
                    The EasyClause web app is distributed by the Law Office
                    Tanase Lucian having its registered office in Bucharest,
                    Sector 6, 8 Aleea Arinii Dornei, block M29, entrance 1, 9th
                    floor, ap. 40, e-mail: office@easyconsult.ro, mobile:
                    +40722559703 and registered with Bucharest bar Association
                    based on decision no 2413/11.10.2007.
                </p>
                <p v-show="showRO">
                    Aplicația web EasyClause este dezvoltată și distribuită de
                    către Cabinetul de Avocat Tănase Lucian cu sediul
                    profesional în București, Sector 6, Aleea Arinii Dornei nr.
                    8, bl. M29, sc.1, etaj 9, ap. 40, email:
                    office@easyconsult.ro, mobil: +4072259703 și înființat
                    conform Deciziei Baroului București nr. 2413/11.10.2007.
                </p>
                <p v-show="showEN">
                    The web app is free to use and do not require to create an
                    account for using it. The user acknowledges and agrees that
                    the use of this app does not create any legal relationship
                    between our Law Office and the user and no legal advice of
                    any kind is provided by our Law Office to the user following
                    the use of the app.
                </p>
                <p v-show="showRO">
                    Applicatia se poate folosi în mod gratuit și nu necesită
                    crearea unui cont de utilizator. Utilizatorul ia la
                    cunostință și este de acord că prin folosirea programului de
                    completare nu ia naștere o relație juridică între Cabinetul
                    de Avocat Tanase Lucian si utilizator si instalarea si
                    folosirea programului de completare nu reprezinta acordarea
                    de asistenta juridica catre utilizator.
                </p>
                <p v-show="showEN">
                    The clauses are prepared taking into consideration their
                    legal effects in accordance with the Romanian laws. However
                    no explicit or implied warranty is offered by the Law Office
                    in respect with the adequacy of these clauses to the user's
                    purposes. The user need to seek legal advice to know what
                    their effects will be in the legal systems that covers the
                    documents where such clauses are inserted.
                </p>
                <p v-show="showRO">
                    Clauzele sunt redactate luand in considerare efectele lor in
                    baza legii romane. Cu toate acestea Cabinetul de Avocat
                    Tanase Lucian nu acorda nicio garantie explicita sau
                    implicita cu privire la adecvarea acestora pentru scopurile
                    in care sunt folosite de catre utilizator. Utilizatorul va
                    trebui sa beneficieze de asistenta juridica pentru a
                    determina efectele juridice din cadrul sistemului de drept
                    ce se aplica documentelor in care sunt folosite clauzele.
                </p>
                <p v-show="showEN">
                    The user is sole responsible for the use of the clauses
                    generated by the app and our Law Office shall not be held
                    responsible or otherwise liable for any damages or loss that
                    user may incur following the use of this web app. In the
                    extent provided by the law our law office shall be
                    exonerated by any liability concerning the use of the web
                    app by the user.
                </p>
                <p v-show="showRO">
                    Utilizatorul este singurul responsabil pentru modul in care
                    utilizeaza clauzele generate de aplicatie si biroul nostru
                    de avocatura nu va fi tinut responsabil sau in altfel
                    raspunzator pentru orice daune sau pierderi pe care
                    utilizatorul le poate suferi ca urmare a folosirii
                    aplicatiei. In masura permisa de lege biroul nostru de
                    avocatura va fi exonerat de orice raspundere legata de
                    folosirea programului de completare de catre utilizator.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppFooter",
    props: {
        showEN: {
            type: Boolean,
        },
        showRO: {
            type: Boolean,
        },
    },
    computed: {},
    methods: {
        displayTerms(event) {
            const target = document.getElementById("overlay-terms");
            target.transition = "all 0.5s";
            target.style.visibility = "visible";
            target.style.top = 0;
        },

        hideTerms() {
            const target = document.getElementById("overlay-terms");
            target.transition = "all 0.5s";
            target.style.top = "100%";
            target.style.visibility = "hidden";
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
    /*  margin-left: -1em;
    margin-right: -1em; */
    height: 40px;
    margin-top: 2em;
    /*color: white;*/

    &-list {
        list-style-type: none;
        text-align: center;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: baseline;
    }

    &-list-item {
        font-size: 0.9rem;
        display: inline-block;
        padding: 0.5rem 1rem;
        cursor: pointer;

        a:link,
        a:visited {
            text-decoration: none;
            color: inherit;
        }

        &:hover {
            background-color: #faf1fc;
        }
    }
}

.list-icon {
    margin-right: 0.5rem;
}

#overlay-terms {
    position: fixed;
    visibility: hidden;
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    cursor: pointer;
    transition: all 0.5s;
}

#terms {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 1rem;
    text-align: justify;
    background-color: #fff;
    padding: 1rem 2rem;
    color: navy;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 0.8em;
}

.terms-header h3 {
    text-align: center;
    position: relative;
    font-size: 1.2rem;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0.5rem;
    font-size: 30px;
    text-decoration: none;
    display: block;
    float: right;
}

/* #overlay-terms:target,
#overlay-privacy:target {
    visibility: visible;
    top: 0;
} */

/* @media (min-width: 801px) {
    .footer {
        margin-top: 2em;
        margin-bottom: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
} */

@media (max-width: 800px) {
    .footer {
        margin-top: 1rem;
        margin-left: 0;
        margin-right: 0;
        &-list {
            flex-flow: column;
            padding: 0;
            align-items: center;
        }
    }

    .terms-header h3 {
        font-size: 1rem;
    }

    #terms {
        width: 75%;
        overflow-y: scroll;
        font-size: 0.8rem;
        height: 100vh;
        text-align: justify;
        padding-top: 1rem;
    }
}

@media (max-width: 600px) {
    .terms-header h3 {
        font-size: 0.9rem;
    }
    #terms {
        width: 90%;
        font-size: 0.8rem;
    }
}
</style>

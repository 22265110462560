import i18n from "./i18n";
const NO_CLAUSE = {
    status: true,
    title: i18n.t("toast.title.info"),
    message: i18n.t("toast.noclause")
};

const NO_USER = {
    status: true,
    title: i18n.t("toast.title.info"),
    message: i18n.t("toast.nouser")
};

const FAV_LIMIT = {
    status: true,
    title: i18n.t("toast.title.info"),
    message: i18n.t("toast.favLimit")
};

const CUSTOM_LIMIT = {
    status: true,
    title: i18n.t("toast.title.info"),
    message: i18n.t("toast.customLimit")
};

const FAV_EXISTS = {
    status: true,
    title: i18n.t("toast.title.info"),
    message: i18n.t("toast.exists")
};

const ADD = {
    status: true,
    title: i18n.t("toast.title.success"),
    message: i18n.t("toast.add")
};

const DELETE = {
    status: true,
    title: i18n.t("toast.title.success"),
    message: i18n.t("toast.delete")
};

const ERROR_ADD = {
    status: true,
    title: i18n.t("toast.title.error"),
    message: i18n.t("toast.errorAdd")
};

const ERROR_DELETE = {
    status: true,
    title: i18n.t("toast.title.error"),
    message: i18n.t("toast.errorDelete")
};

const CHANGE_LABEL = {
    status: true,
    title: i18n.t("toast.title.success"),
    message: i18n.t("toast.changeLabel")
};

export default {
    NO_CLAUSE,
    NO_USER,
    FAV_LIMIT,
    CUSTOM_LIMIT,
    FAV_EXISTS,
    ADD,
    DELETE,
    ERROR_ADD,
    ERROR_DELETE,
    CHANGE_LABEL
};

import toasts from "./../../toasts";
import { setToastContent /*firebaseUrl*/ } from "./../../util";
async function setLocalUser(state) {
    try {
        // const url = firebaseUrl(
        //     `users/${state.auth.user.userId}.json?auth=${state.auth.user.token}`
        // );
        const response = await fetch("https://easylegal.ro/api/user", {
            headers: {
                Authorization: `Bearer ${state.user.token}`,
            },
        });
        const user = await response.json();
        localStorage.setItem("user", JSON.stringify(user));
        return user;
    } catch (err) {
        return false;
    }
}
export default {
    actions: {
        // action for setting a clause as a favorite for a specific user
        async addToFavorite({ commit, rootState }, choice) {
            // check if the user has selected a clause

            if (!choice.label) {
                const toast = setToastContent("info", "noclause");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }
            // verify if the user credentials are stored locally
            // if not retrieve the credentials form firebase and store them locally with setLocaluser function
            const user =
                JSON.parse(localStorage.getItem("user")) ||
                setLocalUser(rootState);
            //console.log(user);
            if (!user) {
                const toast = setToastContent("info", "nouser");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }
            // check if the user has more than 10 favorites and let him know he exceed the limit
            if (user.favorites.length >= 10) {
                const toast = setToastContent("info", "favLimit");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }
            const checkFavorites = user.favorites.filter(
                (favorite) => favorite.label === choice.label
            );
            // check if the selected clause is already selected as favorite
            if (checkFavorites.length > 0) {
                const toast = setToastContent("info", "exists");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }

            // update the Laravel API with the new favorite clause
            try {
                const url = "https://easylegal.ro/api/favorites/add";
                //     firebaseUrl(
                //     `users/${rootState.auth.user.userId}.json?auth=${rootState.auth.user.token}`
                // );
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: user.userId,
                        fav: choice.label,
                        language: rootState.home.EN ? "EN" : "RO",
                    }), //JSON.stringify({ favorites: user.favorites }),
                });
                const result = await response.json();
                if (result.error) {
                    throw new Error(result.error.message);
                }
                // store locally the favorites clause including the new seleted clause
                user.favorites = result.favorites;
                localStorage.setItem("user", JSON.stringify(user));
                const toast = setToastContent("success", "add");
                commit("auth/SET_FAVORITES", user.favorites, {
                    root: true,
                });
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            } catch (err) {
                // console.log(err); // to add error handler
                const toast = setToastContent("error", "errorAdd");
                commit("home/SET_TOAST", toast, { root: true });
            }
        },

        // action for deleting from favorites a specific clause
        async deleteFavorite({ commit, rootState }, payload) {
            console.log(payload);
            // check if the user has selected a clause
            if (!payload.clause) {
                const toast = setToastContent("info", "noclause");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }
            // verify if the user credentials are stored locally
            // if not retrieve the credentials form firebase and store them locally with setLocaluser function
            const user =
                JSON.parse(localStorage.getItem("user")) ||
                setLocalUser(rootState);
            // const newFavorites = user.favorites.filter(
            //     (favorite) => favorite.label !== clause
            // );

            // updating the Laravel API with new favorites clauses resulting from delete operation
            try {
                // const url = firebaseUrl(
                //     `users/${rootState.auth.user.userId}.json?auth=${rootState.auth.user.token}`
                // );
                const url = "https://easylegal.ro/api/favorites/delete";
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Content-Type": "application/json",
                    },
                    //body: JSON.stringify({ favorites: user.favorites }),
                    body: JSON.stringify({
                        user: user.userId,
                        id: parseInt(payload.id),
                    }),
                });
                const result = await response.json();
                if (result.error) {
                    throw new Error(result.error.message);
                }
                // updating locally stored favorites
                user.favorites = result.favorites;
                localStorage.setItem("user", JSON.stringify(user));
                commit("auth/SET_FAVORITES", user.favorites, {
                    root: true,
                });
                const toast = setToastContent("success", "delete");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            } catch (err) {
                console.log(err); // to add error handler
                const toast = setToastContent("error", "errorDelete");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }
        },
        async addToCustom({ commit, rootState }, clause) {
            const user =
                JSON.parse(localStorage.getItem("user")) ||
                setLocalUser(rootState);
            if (!user) {
                const toast = setToastContent("info", "nouser");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }
            if (user.customClauses.length >= 5) {
                const toast = setToastContent("info", "customLimit");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }

            try {
                // const url = firebaseUrl(
                //     `users/${rootState.auth.user.userId}.json?auth=${rootState.auth.user.token}`
                // );
                const url = "https://easylegal.ro/api/custom/add";
                const response = await fetch(url, {
                    method: "POST", //"PATCH"
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        user: user.userId,
                        name: clause.name,
                        content: clause.clauseContent,
                    }),
                });
                const result = await response.json();
                if (result.error) {
                    throw new Error(result.error.message);
                }
                //user.customClauses.push(clause);
                user.customClause = result.customClauses;
                localStorage.setItem("user", JSON.stringify(user));
                commit("auth/SET_CUSTOM_CLAUSES", result.customClauses, {
                    root: true,
                });
                const toast = setToastContent("success", "add");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            } catch (err) {
                //console.log(err);
                const toast = setToastContent("error", "errorAdd");
                commit("home/SET_TOAST", toast, { root: true });
            }
        },
        async deleteCustom({ commit, rootState }, clause) {
            if (!clause) {
                const toast = setToastContent("info", "noclause");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }
            const user =
                JSON.parse(localStorage.getItem("user")) ||
                setLocalUser(rootState);
            if (!user) {
                return commit("home/SET_TOAST", toasts.NO_USER, {
                    root: true,
                });
            }
            // const newCustoms = user.customClauses.filter(
            //     (custom) => custom.clauseValue !== clause
            // );

            try {
                // const url = firebaseUrl(
                //     `users/${rootState.auth.user.userId}.json?auth=${rootState.auth.user.token}`
                // );
                const url = "https://easylegal.ro/api/custom/delete";
                const response = await fetch(url, {
                    method: "POST", //PATCH
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        clause: clause /*user.customClauses*/,
                        user: user.userId,
                    }),
                });
                const result = await response.json();
                if (result.error) {
                    throw new Error(result.error.message);
                }
                user.customClauses = result.customClauses;
                localStorage.setItem("user", JSON.stringify(user));
                commit("auth/SET_CUSTOM_CLAUSES", result.customClauses, {
                    root: true,
                });
                const toast = setToastContent("success", "delete");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            } catch (err) {
                // console.log(err);
                const toast = setToastContent("error", "errorDelete");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }
        },
        async changeCustomLabel({ commit, rootState }, custom) {
            if (custom.labelText === "" || custom.labelText === " ") {
                const toast = setToastContent("info", "noclause");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }
            const user =
                JSON.parse(localStorage.getItem("user")) ||
                setLocalUser(rootState);
            if (!user) {
                const toast = setToastContent("info", "nouser");
                return commit("home/SET_TOAST", toast, { root: true });
            }

            try {
                // const url = firebaseUrl(
                //     `users/${rootState.auth.user.userId}.json?auth=${rootState.auth.user.token}`
                // );
                const url = "https://easylegal.ro/api/custom/edit";
                const response = await fetch(url, {
                    method: "POST", //PATCH
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: custom.customId,
                        name: custom.labelText,
                    }),
                });
                const result = await response.json();
                if (result.error) {
                    throw new Error(result.error.message);
                }

                // user.customClauses.forEach((elem) => {
                //     if (elem.clauseValue === custom.customId) {
                //         elem.clauseLabel = custom.labelText;
                //     }
                // });
                user.customClauses = result.customClauses;
                localStorage.setItem("user", JSON.stringify(user));

                commit("auth/SET_CUSTOM_CLAUSES", result.customClauses, {
                    root: true,
                });
                const toast = setToastContent("success", "changeLabel");
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            } catch (err) {
                // console.log(err);
                const toast = setToastContent("error", "errorChange", err);
                return commit("home/SET_TOAST", toast, {
                    root: true,
                });
            }
        },
    },
};

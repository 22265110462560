<template>
    <div class="header">
        <login-modal :showEN="showEN" :showRO="showRO" />

        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="https://easylegal.ro">
                <span class="logo-text">Easy</span>
                <img
                    src="img/compass.png"
                    width="40"
                    height="40"
                    class="d-inline-block align-middle"
                    alt="logo"
                />
                <span class="logo-text">Legal</span>
            </a>
            <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#appNavbar"
                aria-controls="appNavbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon" />
            </button>
            <div id="appNavbar" class="collapse navbar-collapse">
                <ul class="navbar-nav m-auto">
                    <router-link
                        class="nav-item"
                        tag="li"
                        to="/"
                        active-class="active"
                        exact
                    >
                        <a class="nav-link"
                            ><font-awesome-icon icon="home" />
                            {{ $t("header.home") }}</a
                        >
                    </router-link>
                    <router-link
                        class="nav-item"
                        tag="li"
                        to="/contact"
                        active-class="active"
                    >
                        <a class="nav-link"
                            ><font-awesome-icon icon="envelope" /> Contact</a
                        >
                    </router-link>
                    <router-link
                        class="nav-item"
                        tag="li"
                        to="/about"
                        active-class="active"
                    >
                        <a class="nav-link"
                            ><font-awesome-icon icon="question-circle" />
                            {{ $t("header.about") }}</a
                        >
                    </router-link>
                    <!-- <router-link
                        v-show="!isAuth"
                        class="nav-item"
                        tag="li"
                        to="/signup"
                        active-class="active"
                    >
                        <a class="nav-link"
                            ><font-awesome-icon icon="user-plus" />
                            {{ $t("header.signup") }}
                        </a>
                    </router-link> -->
                    <li
                        v-show="!isAuth"
                        class="nav-item"
                        data-toggle="modal"
                        data-target="#loginModal"
                        active-class="active"
                    >
                        <a class="nav-link"
                            ><font-awesome-icon icon="user-check" />
                            {{ $t("header.login") }}
                        </a>
                    </li>
                    <li v-show="isAuth" class="nav-item" active-class="active">
                        <a class="nav-link" @click="logoutHandler"
                            ><font-awesome-icon icon="sign-out-alt" />
                            {{ $t("header.logout") }}
                        </a>
                    </li>
                    <router-link
                        v-show="isAuth"
                        class="nav-item"
                        active-class="active"
                        tag="li"
                        to="/account"
                    >
                        <a class="nav-link"
                            ><font-awesome-icon icon="user-circle" />
                            {{ $t("header.account") }}
                        </a>
                    </router-link>
                    <!-- <router-link
                        v-show="isAdmin"
                        class="nav-item"
                        active-class="active"
                        tag="li"
                        to="/admin"
                    >
                        <a class="nav-link">
                            <font-awesome-icon icon="toolbox" />
                            Admin
                        </a>
                    </router-link> -->
                </ul>
                <div class="float-lg-right mx-2-lg select-container">
                    <font-awesome-icon icon="globe" class="icon" />
                    <select
                        class="form-select form-select-sm custom-select lang-select"
                        @change="changeLanguage"
                    >
                        <option :value="showEN ? 'EN' : 'RO'">
                            {{ showEN ? $t("header.EN") : $t("header.RO") }}
                        </option>
                        <option :value="showEN ? 'RO' : 'EN'">
                            {{ showEN ? $t("header.RO") : $t("header.EN") }}
                        </option>
                    </select>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import LoginModal from "./LoginModal";
//import Toast from "./Toast";
export default {
    name: "AppHeader",
    components: {
        loginModal: LoginModal,
        //appToast: Toast
    },
    props: {
        showEN: {
            type: Boolean,
        },
        showRO: {
            type: Boolean,
        },
        isAuth: {
            type: Boolean,
        },
        isAdmin: {
            type: Boolean,
        },
        showToast: {
            type: Boolean,
        },
        // isAuth: {
        //     type: Boolean
        // },
        // showToast: {
        //     type: Boolean
        // }
    },
    data() {
        return {
            isActive: false,
        };
    },

    computed: {},

    methods: {
        changeLanguage(event) {
            const lang = event.target.value;
            this.$store.commit("home/CHANGE_LANGUAGE", lang);
            const options = event.target.children;
            for (let i = 0; i < options.length; i++) {
                if (options[i].value !== lang) {
                    options[i].selected = true;
                }
            }
        },
        logoutHandler() {
            this.$store.dispatch("auth/logout");
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#appNavBar {
    position: relative;
}
.logo-text {
    color: #007eb9;
}
li.nav-item {
    cursor: pointer;
}
option {
    font: inherit;
}

.select-container {
    display: flex;
    align-items: center;
}

select {
    outline: none;
}
.lang-select {
    cursor: pointer;
    color: #00188f;
    font-weight: 500;
    letter-spacing: 0.1em;
    // text-align-last: center;
}
option {
    font-weight: 500;
    color: #00188f;
}

.icon {
    margin-right: 0.5rem;
    color: rgba(0, 0, 0, 0.9);
}
</style>

import { setToastContent, firebaseUrl } from "./../../util";
export default {
    state: {
        users: null
    },
    getters: {
        getUsers(state) {
            if (state.users) {
                return state.users;
            }
        }
    },
    mutations: {
        SET_USERS(state, payload) {
            state.users = payload;
        },
        MODIFY_USER(state) {},
        REMOVE_USER(state) {}
    },
    actions: {
        async addCategory({ commit, rootState }, payload) {
            const branch = rootState.home.EN ? "EN" : "RO";
            const token = rootState.auth.user.token;
            const node = rootState.home.EN
                ? rootState.home.clausesEN.length
                : rootState.home.clausesRO.length;
            try {
                const url = firebaseUrl(
                    `/clauses-type/${branch}/${node}.json?auth=${token}`
                );
                const newType = {
                    clauses: {},
                    type: payload.type,
                    typeValue: payload.identifier
                };

                const response = await fetch(url, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(newType)
                });
                const result = await response.json();
                if ("error" in result) {
                    throw new Error(result.error.message);
                }
                const toast = setToastContent("info", "addCategory");
                commit("home/ADD_CATEGORY", newType, { root: true });
                return commit("home/SET_TOAST", toast, { root: true });
            } catch (err) {
                //console.log(err);
                const toast = setToastContent("error", "addCategoryFail", err);
                return commit("home/SET_TOAST", toast, { root: true });
            }
        },
        async removeCategory({ commit, rootState }, payload) {
            const branch = rootState.home.EN ? "EN" : "RO";
            const token = rootState.auth.user.token;
            const types = rootState.home.EN
                ? rootState.home.clausesEN
                : rootState.home.clausesRO;
            const node = types.findIndex(
                item => item.typeValue === payload.category
            );
            try {
                const url = firebaseUrl(
                    `/clauses-type/${branch}/${node}.json?auth=${token}`
                );
                const response = await fetch(url, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const result = await response.json();
                if (result !== null && "error" in result) {
                    throw new Error(result.error.message);
                }
                const toast = setToastContent("info", "removeCategory");
                commit("home/REMOVE_CATEGORY", node, { root: true });
                return commit("home/SET_TOAST", toast, { root: true });
            } catch (err) {
                //console.log(err);
                const toast = setToastContent(
                    "error",
                    "removeCategoryFails",
                    err
                );
                return commit("home/SET_TOAST", toast, { root: true });
            }
        },
        async addClause({ commit, rootState }, payload) {
            const branch = rootState.home.EN ? "EN" : "RO";
            const token = rootState.auth.user.token;
            let node = rootState.home.EN
                ? rootState.home.clausesEN[payload.category].clauses
                    ? rootState.home.clausesEN[payload.category].clauses.length
                    : 0
                : rootState.home.clausesRO[payload.category].clauses
                ? rootState.home.clausesRO[payload.category].clauses.length
                : 0;
            try {
                const url = firebaseUrl(
                    `/clauses-type/${branch}/${payload.category}/clauses/${node}.json?auth=${token}`
                );
                const newClause = {
                    name: payload.clauseName,
                    value: payload.clauseLabel,
                    level: payload.clauseLevel
                };
                const response = await fetch(url, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(newClause)
                });
                const result = await response.json();
                if ("error" in result) {
                    throw new Error("There is an error with Firebase");
                }
                const toast = setToastContent("info", "add");
                commit(
                    "home/ADD_CLAUSE",
                    {
                        node: payload.category,
                        content: newClause
                    },
                    { root: true }
                );
                return commit("home/SET_TOAST", toast, { root: true });
            } catch (err) {
                //console.log(err);
                const toast = setToastContent("error", "errorAdd", err);
                return commit("home/SET_TOAST", toast, { root: true });
            }
        },
        async saveClauseContent({ commit, rootState }, payload) {
            const token = rootState.auth.user.token;
            try {
                const url = firebaseUrl(
                    `clauses-content/${payload.clauseName}.json?auth=${token}`
                );
                let newContent = {};
                payload.clauseContent.forEach((item, index) => {
                    newContent[index] = item;
                });
                const response = await fetch(url, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(newContent)
                });
                const result = await response.json();
                if ("error" in result) {
                    throw new Error(result.error);
                }
                const toast = setToastContent("info", "addClauseContent");
                commit(
                    "home/SAVE_CLAUSE_CONTENT",
                    { name: payload.clauseName, content: newContent },
                    { root: true }
                );
                return commit("home/SET_TOAST", toast, { root: true });
            } catch (err) {
                //console.log(err);
                const toast = setToastContent("error", "addContentFail", err);
                return commit("home/SET_TOAST", toast, { root: true });
            }
        },
        async removeClause({ commit, rootState }, payload) {
            const token = rootState.auth.user.token;
            const branch = rootState.home.EN ? "EN" : "RO";
            try {
                let url = firebaseUrl(
                    `clauses-content/${payload.clauseName}.json?auth=${token}`
                );
                const response = await fetch(url, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const result = await response.json();
                if (result !== null && "error" in result) {
                    throw new Error(result.error.message);
                }
                url = firebaseUrl(
                    `/clauses-type/${branch}/${payload.node}/clauses/${payload.index}.json?auth=${token}`
                );
                const res = await fetch(url, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const data = await res.json();
                //console.log(data);
                if (data !== null && "error" in data) {
                    throw new Error(data.error.message);
                }
                const toast = setToastContent("info", "delete");
                commit(
                    "home/REMOVE_CLAUSE",
                    {
                        node: payload.node,
                        index: payload.index,
                        name: payload.clauseName
                    },
                    { root: true }
                );
                return commit("home/SET_TOAST", toast, {
                    root: true
                });
            } catch (err) {
                //console.log(err);
                const toast = setToastContent("error", "errorDelete", err);
                return commit("home/SET_TOAST", toast, { root: true });
            }
        },
        async fetchUsers({ commit, rootState }, payload) {
            const token = rootState.auth.user.token;
            const url = firebaseUrl(`users.json?auth=${token}`);
            try {
                const response = await fetch(url);
                const data = await response.json();
                //console.log(data);
                commit("SET_USERS", data);
            } catch (err) {
                const toast = setToastContent("error", "fetchUsersFail", err);
                return commit("home/SET_TOAST", toast, { root: true });
            }
        }
    }
};

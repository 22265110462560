import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
//import "./plugins/axios";
//import "./plugins/fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faHome,
    faEnvelope,
    faQuestionCircle,
    faLaptopCode,
    faCopyright,
    faUserShield,
    faFileContract,
    faExclamationCircle,
    faUserPlus,
    faUserCheck,
    faSignOutAlt,
    faUserCircle,
    faTrashAlt,
    faToolbox,
    faPlus,
    faMinusCircle,
    faEdit,
    faSave,
    faWindowClose,
    faGlobe,
    faBookReader
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import "jquery/src/jquery.js";
//import "popper.js/dist/popper.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
//import "./plugins/bootstrap-vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "./registerServiceWorker";
import i18n from "./i18n";

library.add(
    faHome,
    faEnvelope,
    faQuestionCircle,
    faLaptopCode,
    faCopyright,
    faUserShield,
    faFileContract,
    faExclamationCircle,
    faUserPlus,
    faUserCheck,
    faSignOutAlt,
    faUserCircle,
    faTrashAlt,
    faToolbox,
    faPlus,
    faMinusCircle,
    faEdit,
    faSave,
    faWindowClose,
    faGlobe,
    faBookReader
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Vuelidate);
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");

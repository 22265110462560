import i18n from "./i18n";
export const setToastContent = function(title, localeKey, errInfo = "") {
    return {
        status: true,
        title: i18n.t(`toast.title.${title}`),
        message: i18n.t(`toast.${localeKey}`) + " " + errInfo
    };
};
// simple fucntion for constructing the firebase url needed for certain backend operations
export const firebaseUrl = (relativePath, auth = false) => {
    let basePath = "https://easy-clause.firebaseio.com/";
    if (auth) {
        basePath = "https://identitytoolkit.googleapis.com/v1/";
    }
    const url = basePath + relativePath;
    return url;
};
/* fontawesome icons are formed from an svg element and a path element
and when clicked we need to determine which element is the event target */
export const getSVGTarget = target => {
    if (target.nodeName === "svg") {
        return target;
    }
    return target.parentNode;
};

export const getRelevantIcons = (target, iconType) => {
    let saveIcon,
        content,
        editIcon,
        closeIcon,
        removeIcon,
        otherIcon = null;

    switch (iconType) {
        case "edit":
            editIcon = target;
            saveIcon = target.nextElementSibling;
            closeIcon = saveIcon.nextElementSibling;
            content = target.parentNode.parentNode.nextSibling;
            break;
        case "save":
            saveIcon = target;
            editIcon = target.previousElementSibling;
            closeIcon = target.nextElementSibling;
            content = target.parentNode.parentNode.nextSibling;
            break;
        case "close":
            closeIcon = target;
            saveIcon = target.previousElementSibling;
            editIcon = saveIcon.previousElementSibling;
            content = target.parentNode.parentNode.nextSibling;
        case "remove":
            removeIcon = target;
            content = target.parentNode.parentNode.nextSibling;
            break;
        default:
            otherIcon = target;
    }
    return {
        editIcon,
        saveIcon,
        closeIcon,
        removeIcon,
        otherIcon,
        content
    };
};

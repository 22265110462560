<template>
    <div id="app">
        <app-header
            :showEN="getLanguage.EN"
            :showRO="getLanguage.RO"
            :isAuth="isAuth"
            :isAdmin="getUser.isAdmin"
        />
        <div
            v-if="isRefresh"
            class="alert alert-primary text-center"
            role="alert"
        >
            {{ $t("refreshNotice") }}
            <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <button class="btn btn-outline-primary btn-sm" @click="update">
                {{ $t("updateBtn") }}
            </button>
        </div>
        <app-toast
            :showToast="getToast.status"
            :toastTitle="getToast.title"
            :toastMessage="getToast.message"
        />
        <div class="main">
            <router-view />
        </div>
        <div class="spacer"></div>
        <app-footer :showEN="getLanguage.EN" :showRO="getLanguage.RO" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "./components/AppHeader.vue";
import Footer from "./components/AppFooter.vue";
import Toast from "./components/Toast.vue";

export default {
    name: "App",
    components: {
        appHeader: Header,
        appFooter: Footer,
        appToast: Toast,
    },
    data() {
        return {
            registration: null,
            isRefresh: false,
            refreshing: false,
        };
    },
    computed: {
        ...mapGetters("home", ["getLanguage", "getToast"]),
        ...mapGetters("auth", ["isAuth", "getUser"]),
    },
    methods: {
        appUpdateUI: function (event) {
            this.registration = event.detail;
            this.isRefresh = true;
        },
        update() {
            this.isRefresh = false;
            if (this.registration || this.registration.waiting) {
                this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
            }
        },
    },
    created() {
        document.addEventListener("sWUpdateEvent", this.appUpdateUI, {
            once: true,
        });
        navigator.serviceWorker.addEventListener(
            "controllerchange",
            function () {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            }
        );
    },
    mounted() {
        this.$store.dispatch("home/isOffice");
        this.$store.commit("home/FIND_HOST_LANGUAGE");
        this.$store.commit("home/SET_LANGUAGES");
        const userString = localStorage.getItem("user");
        const userData = JSON.parse(userString);
        if (userString && userData.token) {
            this.$store.commit("auth/USER_IS_AUTH", userData);
            // if (new Date(userData.expiresOn) > new Date()) {
            // } else {
            //     this.$store.dispatch("auth/logout");
            // }
        }
    },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap");

html {
    height: 100%;
}

body {
    position: relative;
    height: 100%;
    min-width: 30%;
    margin-left: 2px;
    margin-right: 2px;
    /* padding-bottom: 50px; */
    font-family: "IBM Plex Sans", serif;
    font-size: 16px;
    color: #00188f;
    overflow: auto !important;
}

#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.spacer {
    flex: 1;
}

h3 {
    font-size: 1.5rem;
}

@media (max-width: 600px) {
    body {
        padding-bottom: 0;
        font-size: 14px;
    }
    p {
        font-size: 0.8rem;
    }
    h3 {
        font-size: 1.25rem;
    }
    h4 {
        font-size: 1rem;
        font-weight: 600;
    }
    .btn.btn-sm {
        font-size: 0.75rem;
    }
    .btn.btn-link {
        font-size: 0.85rem;
    }
}
@media (max-width: 990px) {
    .custom-select {
        width: auto;
    }
}
</style>

import i18n from "../../i18n";
import { setToastContent } from "./../../util";
export default {
    state: {
        EN: null,
        RO: null,
        toast: {
            status: false,
            message: "",
            title: "",
        },
        clausesEN: [],
        clausesRO: [],
        clausesContent: {},
        Buttons: {},
        isOffice: false,
        hostLanguage: null,
    },

    getters: {
        getClauses(state) {
            return {
                EN: state.clausesEN,
                RO: state.clausesRO,
            };
        },
        getClausesContent(state) {
            return state.clausesContent;
        },
        getLanguage(state) {
            return {
                EN: state.EN,
                RO: state.RO,
            };
        },
        getHostLang(state) {
            return state.hostLanguage;
        },
        getToast(state) {
            return state.toast;
        },
        getButtons(state) {
            return state.Buttons;
        },
        getOffice(state) {
            return state.isOffice;
        },
    },

    mutations: {
        HIDE_TOAST(state) {
            if (state.toast.status === true) {
                state.toast.status = false;
            }
        },

        SET_LANGUAGES(state) {
            if (state.hostLanguage === "ro" || state.hostLanguage === "ro-RO") {
                state.RO = true;
                state.EN = false;
                i18n.locale = "ro";
            } else {
                state.EN = true;
                state.RO = false;
                i18n.locale = "en";
            }
        },

        FIND_HOST_LANGUAGE(state) {
            state.hostLanguage = state.isOffice
                ? Office.context.displayLanguage
                : window.navigator.language;
        },

        SET_TOAST(state, payload) {
            state.toast.status = payload.status;
            state.toast.title = payload.title;
            state.toast.message = payload.message;
        },

        SET_CLAUSES_TYPE(state, clausesType) {
            state.clausesEN = clausesType.EN;
            state.clausesRO = clausesType.RO;
        },

        SET_CLAUSES_CONTENT(state, payload) {
            state.clausesContent = payload;
        },

        SET_OFFICE(state, payload) {
            state.isOffice = payload;
        },

        ADD_CATEGORY(state, payload) {
            payload.clauses = [];
            if (state.EN) {
                state.clausesEN.push(payload);
            } else {
                state.clausesRO.push(payload);
            }
        },

        REMOVE_CATEGORY(state, index) {
            if (state.EN) {
                state.clausesEN.splice(index, 1);
            } else {
                state.clausesRO.splice(index, 1);
            }
        },

        ADD_CLAUSE(state, payload) {
            if (state.EN) {
                if ("clauses" in state.clausesEN[payload.node]) {
                    const obj = state.clausesEN[payload.node].clauses;
                    obj.push(payload.content);
                    return this._vm.$set(
                        state.clausesEN[payload.node],
                        "clauses",
                        obj
                    );
                }
                const obj = state.clausesEN[payload.node];
                obj.clauses = [];
                this._vm.$set(state.clausesEN[payload.node], "clauses", obj);
            } else {
                if ("clauses" in state.clausesRO[payload.node]) {
                    const obj = state.clausesRO[payload.node].clauses;
                    obj.push(payload.content);
                    return this._vm.$set(
                        state.clausesRO[payload.node],
                        "clauses",
                        obj
                    );
                }
                const obj = state.clausesRO[payload.node];
                obj.clauses = [];
                this._vm.$set(state.clausesRO[payload.node], "clauses", obj);
            }
        },

        SAVE_CLAUSE_CONTENT(state, payload) {
            const clauseContent = Object.values(payload.content);
            state.clausesContent[payload.name] = clauseContent;
        },

        REMOVE_CLAUSE(state, payload) {
            //console.log(payload);
            if (state.EN) {
                state.clausesEN[payload.node].clauses = state.clausesEN[
                    payload.node
                ].clauses.filter((item, index) => {
                    return index !== payload.index;
                });
            } else {
                state.clausesRO[payload.node].clauses = state.clausesRO[
                    payload.node
                ].clauses.filter((item, index) => {
                    return index !== payload.index;
                });
            }
            if (payload.name in state.clausesContent) {
                delete state.clausesContent[payload.name];
            }
        },

        SET_BUTTONS(state, payload) {
            state.Buttons = payload;
        },

        CHANGE_LANGUAGE(state, payload) {
            if (payload === "EN") {
                state.EN = true;
                state.RO = false;
                i18n.locale = "en";
            }
            if (payload === "RO") {
                state.EN = false;
                state.RO = true;
                i18n.locale = "ro";
            }
        },

        DISPLAY_BUTTONS(state, value) {
            for (const item in state.Buttons) {
                if (item === value) {
                    state.Buttons[value] = true;
                } else {
                    state.Buttons[item] = false;
                }
            }
        },
    },

    actions: {
        insertInWordDoc({ commit, state }, payload) {
            if (Office.context.requirements.isSetSupported("WordApi", 1.1)) {
                return Word.run((context) => {
                    const thisDocument = context.document;
                    const range = thisDocument.getSelection();
                    // payload.forEach(function (elem) {
                    //     range.insertParagraph(elem, Word.InsertLocation.before);
                    // });
                    range.insertParagraph(payload, Word.InsertLocation.before);
                    return context.sync();
                }).catch((err) => {
                    //console.log(err.message);
                    const toast = setToastContent("error", "failWord");
                    commit("SET_TOAST", toast);
                });
            } else {
                const toast = setToastContent("error", "notSupported");
                commit("SET_TOAST", toast);
                //console.log("Word version not supported!");
            }
        },

        insertInWebEditor({ commit, state }, payload) {
            const clauseBox = document.querySelector(".ql-editor");
            clauseBox.textContent = "";
            // payload.text.forEach((paragraph) => {
            //     clauseBox.innerHTML += `<p class="text animated slideInDown">${paragraph}</p>`;
            // });
            clauseBox.innerHTML += `<p class="text animated slideInDown">${payload.text}</p>`;
            const elem = document.querySelector(".editor-title");
            elem.setAttribute("data-clause", payload.clause.label);
            elem.setAttribute("data-value", payload.clause.value);
        },

        insertCustomWeb({ commit, state }, payload) {
            const clauseBox = document.querySelector(".ql-editor");
            clauseBox.textContent = "";
            clauseBox.innerHTML += `<p class="text animated slideInDown">${payload.text}</p>`;
        },

        insertCustomInWord({ commit, state }, payload) {
            if (Office.context.requirements.isSetSupported("WordApi", 1.1)) {
                return Word.run((context) => {
                    const thisDocument = context.document;
                    const range = thisDocument.getSelection();
                    range.insertParagraph(payload, Word.InsertLocation.before);
                    return context.sync();
                }).catch((err) => {
                    //console.log(err.message);
                    const toast = setToastContent("error", "failWord");
                    commit("SET_TOAST", toast);
                });
            } else {
                const toast = setToastContent("error", "notSupported");
                commit("SET_TOAST", toast);
                //console.log("Word version not supported!");
            }
        },

        isOffice({ commit, state }) {
            window.onload = () => {
                Office.onReady().then((res) => {
                    if (!res.host) {
                        return commit("SET_OFFICE", false);
                    }
                    commit("SET_OFFICE", true);
                });
            };
        },

        async fetchClausesType({ commit, state }) {
            //const url = firebaseUrl("clauses-type.json");
            const url = "https://easylegal.ro/api/types";
            try {
                const response = await fetch(url);
                const data = await response.json();
                if (data.error) {
                    throw new Error(data.error.message);
                }
                const types = data.EN.map((item) => {
                    return item.typeValue;
                });
                let buttons = {};
                types.forEach((type) => {
                    buttons[type] = false;
                });
                buttons.favorites = false;
                buttons.customs = false;
                //console.log(data);
                commit("SET_BUTTONS", buttons);
                commit("SET_CLAUSES_TYPE", data);
            } catch (err) {
                const toast = setToastContent("error", "appFailure");
                return commit("SET_TOAST", toast);
            }
        },

        async fetchClausesContent({ commit, state }) {
            //const url = firebaseUrl("clauses-content.json");
            const url = "https://easylegal.ro/api/content";
            try {
                const response = await fetch(url);
                const data = await response.json();
                if (data.error) {
                    throw new Error(data.error.message);
                }
                commit("SET_CLAUSES_CONTENT", data);
            } catch (err) {
                const toast = setToastContent("error", "appFailure");
                return commit("SET_TOAST", toast);
            }
        },
    },
};
